export const SET_CHAT_MODE = 'SET_CHAT_MODE'
export const SET_MODEL_ENGINE = 'SET_MODEL_ENGINE'
export const SET_END_DOCUMENT_MODE = 'SET_END_DOCUMENT_MODE'
export const SET_BAR_MODE = 'SET_BAR_MODE'
export const SET_SHOW_FILE_VIEW = 'SET_SHOW_FILE_VIEW'
export const LAUNCH_ALI = 'LAUNCH_ALI'
export const SET_ALI_SEGMENT = 'SET_ALI_SEGMENT'
export const SET_ALI_OPEN_SIDEBAR = 'SET_ALI_OPEN_SIDEBAR'
export const SET_ALI_IS_MODAL_OPEN = 'SET_ALI_IS_MODAL_OPEN'
export const SET_ALI_CHAT_THEME = 'SET_ALI_CHAT_THEME'
export const SET_ALI_DRAWER_WIDTH = 'SET_ALI_DRAWER_WIDTH'
export const SET_FULL_SCREEN = 'SET_FULL_SCREEN'
export const SET_NEW_CONVERSATION = 'SET_NEW_CONVERSATION'
export const SET_WEB_SEARCH = 'SET_WEB_SEARCH'
export const SET_SHOW_DATATOPIC_MODAL = 'SET_SHOW_DATATOPIC_MODAL'
export const SET_SMART_FLOW = 'SET_SMART_FLOW'
export const SET_SELECTED_PERSONA = 'SET_SELECTED_PERSONA'

export function setChatMode(mode) {
  return {
    type: SET_CHAT_MODE,
    payload: mode
  }
}

export function setModelEngine(modelEngine) {
  return {
    type: SET_MODEL_ENGINE,
    payload: modelEngine
  }
}

export function setBarMode(barMode) {
  return {
    type: SET_BAR_MODE,
    payload: barMode
  }
}

export function setShowFileView(showFileView) {
  return {
    type: SET_SHOW_FILE_VIEW,
    payload: showFileView
  }
}

export function launchAli(canLaunchAli) {
  return {
    type: LAUNCH_ALI,
    payload: {
      canLaunchAli
    }
  }
}

export function setAliSegment(isInAliSegment) {
  return {
    type: SET_ALI_SEGMENT,
    payload: {
      isInAliSegment
    }
  }
}

export function setAliOpenSideBar(openSidebar) {
  return {
    type: SET_ALI_OPEN_SIDEBAR,
    payload: {
      openSidebar
    }
  }
}

export function setAliIsModalOpen(isModalOpen) {
  return {
    type: SET_ALI_IS_MODAL_OPEN,
    payload: {
      isModalOpen
    }
  }
}

export function setAliChatTheme(chatTheme) {
  return {
    type: SET_ALI_CHAT_THEME,
    payload: chatTheme
  }
}

export function setAliDrawerWidth(width) {
  return {
    type: SET_ALI_DRAWER_WIDTH,
    payload: width
  }
}

export function setFullScreen(isFullScreen) {
  return {
    type: SET_FULL_SCREEN,
    payload: isFullScreen
  }
}

export function setShowSmartFlow(showSmartFlow) {
  return {
    type: SET_SMART_FLOW,
    payload: showSmartFlow
  }
}

export function setNewConversation(isNewConversation) {
  return {
    type: SET_NEW_CONVERSATION,
    payload: isNewConversation
  }
}

export function setWebSearch(enableWebSearch) {
  return {
    type: SET_WEB_SEARCH,
    payload: enableWebSearch
  }
}

export function setShowDataTopicModal(showDataTopicModal) {
  return {
    type: SET_SHOW_DATATOPIC_MODAL,
    payload: showDataTopicModal
  }
}

export function setSelectedPersona(selectedPersona) {
  return {
    type: SET_SELECTED_PERSONA,
    payload: selectedPersona
  }
}
