import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer, createTransform } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from './reducers/_index'
import { pick } from 'lodash'

const whitelistTransform = createTransform((inboundState, key) => {
  // Select values from the chatbotReducer
  if (key === 'chatbotReducer') {
    return pick(inboundState, ['aliDrawerWidth'])
  }
  return inboundState
})

const persistentConfig = {
  key: 'root',
  storage,
  transforms: [whitelistTransform],
  blacklist: [
    'appOnboardingReducer',
    'collectionOnboardingReducer',
    'customCollectionsReducer',
    'collectionsReducer',
    'appsReducer',
    'favoriteAppsReducer',
    'recentlyAppsReducer',
    'chatbotReducer',
    'smartFlowReducer',
    'searchAppsReducer',
    'notificationContextReducer',
    'reviewsReducer',
    'widgetsReducer',
    'userContextReducer',
    'profileReducer'
  ]
}

const persistedReducer = persistReducer(persistentConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
})
const persistor = persistStore(store)

export { store, persistor }
