import {
  ADD_WORKSPACE,
  REMOVE_WORKSPACE,
  UPDATE_WORKSPACE,
  SET_ACTIVE_WORKSPACE,
  REARRANGE_WORKSPACE,
  SET_WORKSPACE_DATA
} from '../types/workspaceConstants'

const initialState = {
  workspaces: { workspaceList: [], activeWorkspace: null }
}

export default function workspaceReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_WORKSPACE: {
      return {
        ...state,
        workspaces: {
          ...state.workspaces,
          workspaceList: [...state.workspaces.workspaceList, action.payload],
          activeWorkspace: action.payload.id
        }
      }
    }

    case UPDATE_WORKSPACE: {
      const updatedWorkspaces = state.workspaces.workspaceList.map((workspace) => {
        return workspace.id === action.payload.id ? action.payload : workspace
      })
      return {
        ...state,
        workspaces: {
          ...state.workspaces,
          workspaceList: updatedWorkspaces
        }
      }
    }

    case REMOVE_WORKSPACE: {
      const updatedWorkspace = state.workspaces.workspaceList.filter(
        (item) => item.id !== action.payload.id
      )

      return {
        ...state,
        workspaces: {
          ...state.workspaces,
          workspaceList: updatedWorkspace,
          ...(action.payload.id === state.workspaces.activeWorkspace
            ? {
                activeWorkspace: updatedWorkspace.length
                  ? updatedWorkspace[updatedWorkspace.length - 1].id
                  : null
              }
            : {})
        }
      }
    }

    case SET_ACTIVE_WORKSPACE: {
      return {
        ...state,
        workspaces: {
          ...state.workspaces,
          activeWorkspace: action.payload
        }
      }
    }

    case REARRANGE_WORKSPACE: {
      return {
        ...state,
        workspaces: {
          ...state.workspaces,
          workspaceList: action.payload
        }
      }
    }

    case SET_WORKSPACE_DATA: {
      return {
        workspaces: {
          workspaceList: action.payload.workspaceList ? action.payload?.workspaceList : [],
          activeWorkspace: action.payload.activeWorkspace ? action.payload?.activeWorkspace : null
        }
      }
    }

    default:
      return state
  }
}
